<template>
  <div class="elv-ledger-journal-type-table-container">
    <div class="elv-ledger-journal-type-table-box">
      <el-table
        ref="tableListRef"
        v-loading="props.tableLoading"
        :data="listData"
        border
        height="100%"
        class="elv-journal-type-table"
        header-cell-class-name="elv-journal-type-table-header__cell"
        header-row-class-name="elv-journal-type-table-header"
        cell-class-name="elv-journal-type-table-row__cell"
        :row-class-name="tableRowClassName"
        :span-method="objectSpanMethod"
        @cell-mouse-enter="handleCellMouseEnter"
        @cell-mouse-leave="handleCellMouseLeave"
        @row-click="onCellClicked"
      >
        <el-table-column width="120" :label="t('title.journalTypeId')">
          <template #default="{ row }">
            {{ row.code }}
          </template>
        </el-table-column>

        <el-table-column
          :label="t('report.journalTypeName')"
          width="250"
          class-name="elv-journal-type-table-row__cell-name"
          :show-overflow-tooltip="true"
        >
          <template #default="{ row }">
            {{ locale === 'en' ? row.name : row?.nameCN || row.name }}
          </template>
        </el-table-column>
        <el-table-column :label="t('title.journalTypeCode')" width="120" header-align="center" align="center">
          <template #default="{ row }">
            {{ row.journalNoPrefix }}
          </template>
        </el-table-column>
        <el-table-column :label="t('report.Dr/Cr')" width="60" header-align="center" align="center">
          <template #default="{ row }">
            {{ row?.balanceType }}
          </template>
        </el-table-column>
        <el-table-column
          :label="t('report.Account')"
          min-width="150"
          :show-overflow-tooltip="true"
          class-name="elv-journal-type-table-row__cell-account"
        >
          <template #default="{ row }">
            <el-tooltip
              v-if="row?.chartOfAccountCalculator === 'TREASURY_ACCOUNT'"
              ref="elvTableTipsRef"
              :show-arrow="false"
              effect="dark"
              placement="top"
              popper-class="elv-journal-type-tips"
              :show-after="500"
            >
              <span>{{ `{ ${t('report.treasuryAccount')} }` }}</span>
              <template #content>
                <div class="elv-table-tips-content">{{ t('message.treasuryAccountTips') }}</div>
              </template>
            </el-tooltip>
            <template v-else>
              {{
                locale === 'en' ? row?.chartOfAccount?.name : row?.chartOfAccount?.nameCN || row?.chartOfAccount?.name
              }}
            </template>
          </template>
        </el-table-column>
        <el-table-column :label="t('title.GSTRate')" min-width="200">
          <template #default="{ row }">
            <template v-if="row?.gstType">
              {{
                row?.gstType === 'NA'
                  ? '-'
                  : `${transformI18n(find(journalTypeGstOptions, { value: row?.gstType })?.label)}${
                      row?.gstType !== 'EXEMPT' && row?.gstType !== 'NONE'
                        ? row?.gstType === 'ZERO'
                          ? ': 0%'
                          : `: ${row?.gstRatio}%`
                        : ''
                    }`
              }}
            </template>
            <template v-else>-</template>
          </template>
        </el-table-column>
        <el-table-column :label="t('common.createdBy')" min-width="120">
          <template #default="{ row }">
            {{ row?.creatorType === 'USER' ? row?.user?.name : t('common.system') }}
          </template>
        </el-table-column>
        <el-table-column label="" width="80" class-name="elv-journal-type-table-row__operating" align="center">
          <template #default="{ row }">
            <SvgIcon name="sources-edit" width="18" fill="#838D95" height="18" @click="onEditJournalType(row)" />

            <template
              v-if="
                ![
                  'internal_transfer',
                  'buy_token',
                  'sell_token',
                  'token_trade',
                  'adjusting_unrealized_crypto_assets',
                  'adjusting_realized_crypto_assets',
                  'adjusting_realized_crypto_liabilities',
                  'adjusting_unrealized_crypto_liabilities',
                  'm2m_assets',
                  'm2m_liabilities'
                ].includes(row.slug)
              "
            >
              <SvgIcon
                v-if="row?.creatorType !== 'SYSTEM'"
                name="sources-delete"
                width="18"
                height="18"
                fill="#838D95"
                @click="onDeleteJournalType(row)"
              />
            </template>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <elv-pagination
      class="elv-journals-list-pagination"
      size-popper-class="elv-journals-pagination__sizes-popper"
      :limit="props.journalTypeParams.limit"
      :current-page="props.journalTypeParams.page"
      :total="transactionStore.journalTypeList.length ? transactionStore.journalTypeList.length - 1 : 0"
      @current-change="onChangePage"
      @size-change="onChangePageSize"
    />
  </div>

  <DeleteJournalTypeDialog
    ref="deleteJournalTypeDialogRef"
    :itemData="currentData"
    @onResetList="emit('onResetList')"
  />
</template>

<script setup lang="ts">
import { find } from 'lodash-es'
import { useI18n } from 'vue-i18n'
import { ElMessage } from 'element-plus'
import { transformI18n } from '@/i18n/index'
import type { TableColumnCtx } from 'element-plus'
import { useEntityStore } from '@/stores/modules/entity'
import { journalTypeGstOptions } from '@/config/reports/index'
import { useTransactionStore } from '@/stores/modules/transactions'
import DeleteJournalTypeDialog from './DeleteJournalTypeDialog.vue'
import { JournalTypeItemType, JournalTypeEntryLineType } from '#/TransactionsTypes'

const props = defineProps({
  tableData: {
    type: Array as () => JournalTypeItemType[],
    required: true
  },
  tableLoading: {
    type: Boolean,
    default: false
  },
  tableHeight: {
    type: String,
    required: true
  },
  journalTypeParams: {
    type: Object,
    required: true
  }
})

const { t, locale } = useI18n()
const entityStore = useEntityStore()
const transactionStore = useTransactionStore()
const emit = defineEmits(['onEditJournalType', 'onResetList', 'onChangePage', 'onChangePageSize'])

interface SpanMethodProps {
  row: any
  column: TableColumnCtx<any>
  rowIndex: number
  columnIndex: number
}

const currentIndex = ref('')
const currentData = ref<any>({})
const tableListRef = useTemplateRef('tableListRef')
const deleteJournalTypeDialogRef = useTemplateRef('deleteJournalTypeDialogRef')

const currentEntityPermission = computed(() => {
  return entityStore.entityPermission()
})

const listData = computed(() => {
  const list = props.tableData.flatMap((item: JournalTypeItemType, index: number) => {
    if (item.entryLines.length === 0) {
      return {
        ...item,
        direction: 'OUT',
        rowIndex: index
      }
    }
    return item.entryLines.map((entry: JournalTypeEntryLineType) => {
      return {
        ...item,
        ...entry,
        direction: 'OUT',
        rowIndex: index
      }
    })
  })
  return list.length ? list : props.tableData
})

const onChangePage = (page: number) => {
  emit('onChangePage', page)
}

const onChangePageSize = (limit: number) => {
  emit('onChangePageSize', limit)
}

const onEditJournalType = async (row: any) => {
  try {
    if (['MEMBER', ''].includes(currentEntityPermission.value?.role)) {
      ElMessage.warning(t('message.noPermission'))
      return
    }
    currentData.value = row
    emit('onEditJournalType', row)
  } catch (error) {
    console.error(error)
  }
}
// eslint-disable-next-line no-unused-vars
const onCellClicked = async (row: any, column: any, e: any) => {
  try {
    console.log(row)
  } catch (error: any) {
    ElMessage.error(error.message)
  }
}
const onDeleteJournalType = (row: any) => {
  if (['MEMBER', ''].includes(currentEntityPermission.value?.role)) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  currentData.value = row
  deleteJournalTypeDialogRef.value?.onCheckDeleteDialog()
}

// eslint-disable-next-line no-unused-vars, consistent-return
const objectSpanMethod = ({ row, column, rowIndex, columnIndex }: SpanMethodProps) => {
  // eslint-disable-next-line no-unsafe-optional-chaining
  if (listData.value.length <= props.tableData?.length) {
    return {
      rowspan: 1,
      colspan: 1
    }
  }
  if (columnIndex < 3 || columnIndex > 5) {
    let rowspan = 1
    for (let i = rowIndex + 1; i < listData.value.length; i += 1) {
      if (listData.value[i].journalTypeId === row.journalTypeId) {
        rowspan += 1
      } else {
        break
      }
    }
    if (!row.entryLines.length) {
      return {
        rowspan: 1,
        colspan: 1
      }
    }
    if (rowIndex % rowspan === 0 && rowspan === row.entryLines.length) {
      return {
        rowspan,
        colspan: 1
      }
    }
    if (
      row.entryLines.length &&
      rowspan === row.entryLines.length &&
      row?.chartOfAccountId === row.entryLines[0]?.chartOfAccountId
    ) {
      return {
        rowspan,
        colspan: 1
      }
    }
    return {
      rowspan: 0,
      colspan: 0
    }
  }
}

const handleCellMouseEnter = (row: any) => {
  currentIndex.value = row.journalTypeId
}

const handleCellMouseLeave = () => {
  currentIndex.value = ''
}

const tableRowClassName = (data: any) => {
  if (data?.row.journalTypeId === currentIndex.value) {
    return 'is-hover-row'
  }
  if ((data.row.rowIndex + 1) % 2 === 0) {
    return 'elv-table__row--striped'
  }
  return ''
}

defineExpose({
  ref: tableListRef
})
</script>

<style lang="scss">
.elv-journal-type-table {
  --el-table-border-color: #d4dce5;
  --el-table-border: 1px solid #edf0f3;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #d4dce6;
  border-right: 0px;
  border-left: 0px;
  border-bottom-width: 1px;

  .el-table__inner-wrapper {
    width: 100%;

    &::after,
    &::before {
      width: 0;
      height: 0;
    }
  }

  &.el-table--border {
    border-bottom-color: #edf0f3;

    &::after,
    &::before {
      width: 0;
      height: 0;
    }
  }

  .el-table__body-wrapper {
    height: 616px;

    .el-scrollbar__view {
      width: 100%;
      margin-bottom: 0px !important;
    }
  }

  .el-table__border-left-patch {
    width: 0;
  }

  .el-table__row {
    &.elv-table__row--striped {
      background: #f9fafb;
    }

    &.is-hover-row td {
      background: #f5f7fa;
    }

    &:hover td {
      background: transparent;
    }
  }

  .elv-journal-type-table-header {
    background: #eef4fb;

    .elv-journal-type-table-header__cell {
      background: #eef4fb;
      height: 36px;
      box-sizing: border-box;
      border-right-color: #e4e7eb;
      border-bottom-color: #ced7e0;
      padding: 0px;

      &.is-center {
        text-align: center;

        .cell {
          justify-content: center;
        }
      }

      &:last-child {
        border-right: 0px;
      }

      &.is-right .cell {
        justify-content: flex-end;
      }

      .cell {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        color: #666d74;
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-items: center;
      }
    }
  }

  .elv-journal-type-table-row__cell {
    padding: 0;
    height: 42px;

    // &:last-child {
    //   border-right: 0px;
    // }

    &.is-right .cell {
      text-align: right;
    }

    &.is-center .cell {
      text-align: center;
    }

    .cell {
      padding: 0 10px;
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      color: #0e0f11;
      line-height: 16px;
    }

    .elv-journal-type-table-row__cell-activity {
      font-weight: 500;
    }

    &.elv-journal-type-table-row__cell-account {
      .cell {
        white-space: nowrap;

        span {
          color: #838d95;
          font-family: 'Barlow';
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }

    &.elv-journal-type-table-row__cell-name {
      .cell {
        font-weight: 600;
        line-height: normal;
        white-space: nowrap;

        // display: -webkit-box;
        // -webkit-box-orient: vertical;
        // -webkit-line-clamp: 1;
        // text-overflow: ellipsis;
        // overflow: hidden;
      }
    }

    &.elv-journal-type-table-row__operating {
      .cell {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;

        svg {
          fill: #838d95;

          &.is-disabled {
            cursor: not-allowed !important;
          }

          &:not(.is-disabled):hover {
            cursor: pointer;
            fill: #1e2024;
          }
        }
      }
    }

    &.el-table-column--selection {
      .cell {
        width: 100%;
        padding: 0px;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.elv-journal-type-tips.el-popper {
  width: 224px;
  padding: 5px 10px 8px 10px;

  .elv-table-tips-content {
    color: #fff;
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    padding: 0px;
    word-break: break-word;
  }
}

.elv-journals-pagination__sizes-popper.el-popper {
  transform: translateY(10.5px);
}
</style>
<style lang="scss" scoped>
.elv-ledger-journal-type-table-container {
  height: calc(100% - 48px);
  position: relative;

  .elv-ledger-journal-type-table-box {
    height: calc(100% - 54px);
    position: relative;

    .elv-ledger-journal-type-table-warp {
      height: 100%;
      position: relative;
    }
  }
}
</style>
