<template>
  <el-dialog
    v-model="showJournalTypeDialog"
    width="30%"
    align-center
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    class="elv-ledger-journal-type-dialog"
    @close="onCloseDialog"
  >
    <template #header="{ titleId }">
      <h4 :id="titleId" class="elv-ledger-journal-type-dialog-header__title">
        {{ props.model === 'add' ? t('title.newJournalType') : t('title.editJournalType') }}
      </h4>
    </template>
    <div v-loading="dialogLoading" class="elv-ledger-journal-type-content">
      <el-form ref="formRef" :model="journalTypeForm" label-position="top" :rules="rules">
        <el-form-item :label="t('title.journalTypeName')" prop="name">
          <el-input
            v-model="journalTypeForm.name"
            placeholder=" "
            :class="[projectDetail?.plan === ProjectPermissionLevel.OLD_BASIC ? 'is-short' : 'is-shorter']"
            :disabled="props.model === 'edit' && (checkStatusLoading || journalTypeDetail?.creatorType === 'SYSTEM')"
          />
        </el-form-item>
        <div class="elv-ledger-journal-type-form-item__base">
          <el-form-item :label="t('title.journalTypeId')" prop="code" class="elv-ledger-journal-type-form-item__id">
            <el-input
              v-model="journalTypeForm.code"
              placeholder=" "
              maxlength="5"
              :class="[projectDetail?.plan === ProjectPermissionLevel.OLD_BASIC ? 'is-short' : 'is-shorter']"
              :disabled="props.model === 'edit' && checkStatusLoading"
            />
          </el-form-item>
          <el-form-item :label="t('title.journalTypeCode')" prop="journalNoPrefix">
            <el-input
              v-model="journalTypeForm.journalNoPrefix"
              placeholder=" "
              maxlength="5"
              :class="[projectDetail?.plan === ProjectPermissionLevel.OLD_BASIC ? 'is-short' : 'is-shorter']"
              :disabled="props.model === 'edit' && (checkStatusLoading || checkStatusData.length > 0)"
            />
          </el-form-item>
          <el-form-item :label="t('common.activity')" prop="journalTypeGroup">
            <el-select
              v-model="journalTypeForm.group"
              :class="[projectDetail?.plan === ProjectPermissionLevel.OLD_BASIC ? 'is-short' : 'is-shorter']"
              :disabled="
                props.model === 'edit' &&
                (checkStatusLoading ||
                  journalTypeDetail?.group === 'GAIN_OR_LOSS_ADJUSTING' ||
                  checkStatusData.length > 0)
              "
            >
              <el-option
                v-for="item in groupOptions"
                :key="item.value"
                :label="transformI18n(item.label)"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item
            v-if="projectDetail?.plan !== ProjectPermissionLevel.OLD_BASIC"
            :label="t('title.brokerInventoryType')"
            prop="isBrokerInventory"
          >
            <el-select
              v-model="journalTypeForm.isBrokerInventory"
              :disabled="
                (props.model === 'edit' &&
                  (checkStatusLoading ||
                    journalTypeDetail?.group === 'GAIN_OR_LOSS_ADJUSTING' ||
                    checkStatusData.length > 0)) ||
                hasGstType
              "
              class="is-shorter"
              @change="onChangeBrokerInventoryType"
            >
              <el-option label="True" :value="true" />
              <el-option label="False" :value="false" />
            </el-select>
            <template #error>
              <div>
                <el-tooltip
                  effect="dark"
                  placement="bottom"
                  popper-class="elv-report-table-tips elv-ledger-journal-type-form-broker-tips"
                  overlay-classname="elv-report-table-tips"
                  :show-after="500"
                >
                  <div class="elv-ledger-journal-type-form-broker-error">⊙ Non-compliant</div>
                  <template #content>
                    <p>
                      {{ t('message.brokerInventoryErrorInfo') }}
                    </p>
                  </template>
                </el-tooltip>
              </div>
            </template>
          </el-form-item>
        </div>
        <div class="elv-ledger-journal-type-form-item__line">{{ t('title.entryLines') }}</div>
        <div class="elv-ledger-journal-type-form-item__entry-list">
          <div class="elv-ledger-journal-type-form-item__entry-title">
            <span
              v-show="props.model === 'add' || (props.model === 'edit' && journalTypeDetail?.creatorType !== 'SYSTEM')"
              >Dr/Cr</span
            ><span
              v-show="props.model === 'add' || (props.model === 'edit' && journalTypeDetail?.creatorType !== 'SYSTEM')"
              >{{ t('report.Account') }}</span
            ><span
              v-show="
                journalTypeForm?.entryLines.some((i: any) => i?.chartOfAccountId !== '') && entityDetail?.enableGst
              "
              >{{ t('title.GSTRate') }}</span
            >
          </div>
          <template
            v-if="
              props.model === 'add' ||
              (props.model === 'edit' &&
                (journalTypeDetail?.creatorType !== 'SYSTEM' || journalTypeForm?.entryLines?.length))
            "
          >
            <div
              v-for="(item, index) in journalTypeForm.entryLines"
              :key="item.index"
              class="elv-ledger-journal-type-form-item__entry-item"
            >
              <div class="elv-ledger-journal-type-form-item__entry-item__balance">{{ item.balanceType }}</div>
              <el-form-item label="" prop="journalTypeForm.entryLines[index].chartOfAccountId">
                <ChartOfAccountOverlayDropdown
                  ref="chartOfAccountOverlayDropdownRefs"
                  v-model="item.chartOfAccountId"
                  :show-treasury-account="true"
                  class="elv-ledger-journal-type-form-item__entry-item__account"
                  :disabled="disabledChartOrAccount(item).value"
                  :style="{ width: item.chartOfAccountId && entityDetail?.enableGst ? '194px' : '484px' }"
                  @onChangeChartOfAccount="onChangeChartOfAccount(index)"
                  @onSelectTreasuryAccount="onSelectTreasuryAccount(index)"
                />
              </el-form-item>
              <template v-if="item?.chartOfAccountId !== '' && entityDetail?.enableGst">
                <el-form-item label="" class="elv-ledger-journal-type-form-item__entry-item__type">
                  <el-select
                    v-model="item.gstType"
                    :teleported="false"
                    :disabled="disabledGstType(item).value"
                    :popper-append-to-body="false"
                    @change="onChangeGSTRateType(item.index)"
                  >
                    <el-option
                      v-for="i in journalTypeGstOptionsData(index).value"
                      :key="i.value"
                      :label="transformI18n(i?.label)"
                      :value="i?.value"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item
                  v-show="
                    item.gstType !== 'NONE' &&
                    item.gstType !== 'EXEMPT' &&
                    item.gstType !== '' &&
                    item.gstType !== 'NA' &&
                    entityDetail?.enableGst &&
                    !['gst_output', 'gst_input'].includes(currentChartOfAccount(item.chartOfAccountId).value?.slug)
                  "
                  label=""
                  class="elv-ledger-journal-type-form-item__entry-item__percent"
                >
                  <el-input-number
                    v-model="item.gstRatio"
                    :disabled="item.gstType === 'ZERO' || disabledGstType(item).value"
                    :precision="0"
                    :max="100"
                    :controls="false"
                    class="is-shorter-entry"
                  />
                  <div class="symbol">%</div>
                </el-form-item>
              </template>
              <div class="elv-ledger-journal-type-form-item__entry-item__delete">
                <SvgIcon
                  name="sources-delete"
                  width="16"
                  height="16"
                  fill="#AAAFB6"
                  :class="{ 'is-disabled': hasGstType }"
                  @click="onDeleteEntry(item.index)"
                />
              </div>
            </div>
          </template>
          <div
            v-show="props.model === 'edit' && checkStatusLoading"
            class="elv-ledger-journal-type-checking"
            :style="{
              marginTop:
                checkStatusLoading && journalTypeDetail?.creatorType === 'SYSTEM' && !journalTypeForm.entryLines.length
                  ? '0px'
                  : '8px'
            }"
          >
            <img src="@/assets/img/reports/sources-sync-loading.png" alt="loading" />{{
              t('title.checkingJournalTypeStatus')
            }}
          </div>
          <div
            v-show="
              props.model === 'edit' &&
              !checkStatusLoading &&
              (checkStatusData?.length || journalTypeDetail?.creatorType === 'SYSTEM')
            "
            class="elv-ledger-journal-type-info"
            :style="{
              marginTop:
                !checkStatusLoading &&
                journalTypeDetail?.creatorType === 'SYSTEM' &&
                !journalTypeForm?.entryLines?.length
                  ? '0px'
                  : '8px'
            }"
          >
            <SvgIcon name="information-line" height="16" width="16" fill="#1753EB" />
            {{
              journalTypeDetail?.creatorType === 'SYSTEM'
                ? t('message.editJournalTypeOfAutomationInfo')
                : t('message.editJournalTypeOfJournalInfo')
            }}
          </div>
        </div>
        <el-dropdown
          trigger="click"
          placement="bottom-start"
          :show-arrow="false"
          :teleported="false"
          popper-class="elv-ledger-journal-type__dropdown-popper"
        >
          <elv-button
            height="32"
            width="110"
            class="elv-ledger-journal-type-form-item__entry-add"
            :disabled="hasGstType"
          >
            <SvgIcon name="add-default" height="16" width="16" fill="#1753EB" />
            {{ t('button.addLine') }}
          </elv-button>
          <template #dropdown>
            <el-dropdown-menu class="elv-ledger-journal-type__dropdown elv-language" size="large">
              <el-dropdown-item class="elv-ledger-journal-type__dropdown--item" @click="onAddEntry('Dr')"
                >Dr</el-dropdown-item
              >
              <el-dropdown-item class="elv-ledger-journal-type__dropdown--item" @click="onAddEntry('Cr')"
                >Cr</el-dropdown-item
              >
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </el-form>
    </div>
    <template #footer>
      <elv-button
        height="44"
        width="100"
        round
        type="primary"
        :loading="saveLoading"
        :disabled="dialogLoading || saveDisabled"
        @click="onSaveChartOfAccount"
      >
        {{ t('button.save') }}
      </elv-button>
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import LedgerApi from '@/api/LedgerApi'
import { ElMessage } from 'element-plus'
import { $t, transformI18n } from '@/i18n/index'
import { ProjectPermissionLevel } from '#/ProjectTypes'
import { useEntityStore } from '@/stores/modules/entity'
import { useComputedHook } from '@/hooks/useComputedHook'
import type { FormInstance, FormRules } from 'element-plus'
import { find, cloneDeep, findIndex, isEmpty } from 'lodash-es'
import { useGlobalStore } from '@/stores/modules/global/index'
import { reportTransactionType, journalTypeGstOptions } from '@/config/reports/index'
import ChartOfAccountOverlayDropdown from '../../../components/ChartOfAccountOverlayDropdown.vue'

const props = defineProps({
  currentData: {
    type: Object,
    default: () => {
      return {}
    }
  },
  model: {
    type: String,
    default: 'add' // add, edit
  }
})

const route = useRoute()
// eslint-disable-next-line no-unused-vars
const { t, locale } = useI18n()
const entityStore = useEntityStore()
const globalStore = useGlobalStore()
const { entityDetail } = storeToRefs(entityStore)

const emit = defineEmits(['onResetList'])

const journalTypeDetail: any = ref({})
const showJournalTypeDialog = ref(false)
const saveLoading = ref(false)
const checkStatusData: any = ref([])
const chartOfAccountOverlayDropdownRefs = ref()
const originalGstTypeIndex = ref<string | number>('')
const checkStatusLoading = ref(false)
const dialogLoading = ref(false)
const brokerInventoryValidate = ref(false)
const journalTypeForm = ref({
  name: '',
  journalNoPrefix: '',
  code: '',
  group: 'OPERATING',
  entryLines: [
    {
      index: 0,
      chartOfAccountId: '',
      chartOfAccountCalculator: '',
      balanceType: 'Dr',
      gstType: '',
      gstRatio: 0
    },
    {
      index: 1,
      chartOfAccountId: '',
      chartOfAccountCalculator: '',
      balanceType: 'Cr',
      gstType: '',
      gstRatio: 0
    }
  ],
  isBrokerInventory: false
})
const formRef = useTemplateRef<FormInstance>('formRef')

const entityId = computed(() => {
  return String(route.params?.entityId as string)
})

const enableGst = computed(() => {
  return entityStore.entityDetail?.enableGst
})

const projectDetail = computed(() => {
  return globalStore.projectDetail(entityStore.entityDetail?.projectId)
})

const hasGstType = computed(() => {
  const gstEntry = journalTypeForm.value.entryLines.find(
    (i: any) => i.gstType !== 'NONE' && i.gstType !== 'NA' && i.gstType !== ''
  )
  return !isEmpty(gstEntry)
})

const saveDisabled = computed(() => {
  return (
    journalTypeForm.value.entryLines.some((i: any) => i.chartOfAccountId === '') ||
    journalTypeForm.value.entryLines.length < 2 ||
    !journalTypeForm.value.name ||
    !journalTypeForm.value.journalNoPrefix ||
    !journalTypeForm.value.code
  )
})

const disabledGstType: any = useComputedHook((item: any) => {
  if (journalTypeForm.value.isBrokerInventory) return true
  if (
    (props.model === 'edit' && (checkStatusData.value.length > 0 || checkStatusLoading.value)) ||
    item.gstType === 'NA'
  )
    return true

  const hasDrAndCr =
    journalTypeForm.value.entryLines.some((entry: any) => entry.balanceType === 'Dr') &&
    journalTypeForm.value.entryLines.some((entry: any) => entry.balanceType === 'Cr')
  if (
    journalTypeForm.value.entryLines.length < 2 ||
    (journalTypeForm.value.entryLines.length > 2 && !hasGstType.value) ||
    !hasDrAndCr
  )
    return true
  const gstEntry = journalTypeForm.value.entryLines.find((i: any) => i.gstType !== 'NONE' && i.gstType !== 'NA')
  if (
    !isEmpty(gstEntry) &&
    (journalTypeForm.value.entryLines.length > 2 ||
      (journalTypeForm.value.entryLines.length === 2 && gstEntry.gstType === 'EXEMPT'))
  ) {
    return item.balanceType !== gstEntry?.balanceType
  }
  return false
})

const disabledChartOrAccount: any = useComputedHook((item: any) => {
  if (
    props.model === 'edit' &&
    (checkStatusData.value.length > 0 || checkStatusLoading.value || journalTypeDetail.value?.creatorType === 'SYSTEM')
  )
    return true
  const isEvenIndex = (item.index + 1) % 2 === 0
  const isDr = item.balanceType === 'Dr'
  const isCr = item.balanceType === 'Cr'
  const { entryLines } = journalTypeForm.value
  const gstBalanceType = entryLines.find((i: any) => i.gstType !== 'NONE' && i.gstType !== 'NA')?.balanceType
  const hasMoreThanTwoLines = entryLines?.length > 2
  const firstLineHasGst = entryLines[0]?.gstType !== 'NONE' && entryLines[0]?.gstType !== 'NA'
  const secondLineHasGst = entryLines[1]?.gstType !== 'NONE' && entryLines[1]?.gstType !== 'NA'
  const thirdLineHasGst = entryLines[2]?.gstType !== 'NONE' && entryLines[2]?.gstType !== 'NA'

  if (enableGst.value) {
    if (isCr && gstBalanceType === 'Dr' && hasMoreThanTwoLines && !isEvenIndex && firstLineHasGst) return true
    if (isCr && gstBalanceType === 'Cr' && hasMoreThanTwoLines && !isEvenIndex && secondLineHasGst) return true
    if (isDr && gstBalanceType === 'Cr' && hasMoreThanTwoLines && isEvenIndex && thirdLineHasGst) return true
  }

  return false
})

const groupOptions = computed(() => {
  return props.model === 'edit' &&
    !isEmpty(journalTypeDetail.value) &&
    journalTypeDetail.value?.group === 'GAIN_OR_LOSS_ADJUSTING'
    ? [...reportTransactionType, { label: $t('report.gainOrLossAdjusting'), value: 'GAIN_OR_LOSS_ADJUSTING' }]
    : reportTransactionType
})

const gstOutputChartOfAccountId = computed(() => {
  return find(entityStore.chartOfAccountList, { slug: 'gst_output' })?.chartOfAccountId ?? ''
})

const gstInputChartOfAccountId = computed(() => {
  return find(entityStore.chartOfAccountList, { slug: 'gst_input' })?.chartOfAccountId ?? ''
})

const currentChartOfAccount: any = useComputedHook((chartOfAccountId: string) => {
  return find(entityStore.chartOfAccountList, { chartOfAccountId })
})

const chartOfAccountGstOptional: any = useComputedHook((chartOfAccountId: string) => {
  const chartOfAccount = currentChartOfAccount(chartOfAccountId).value
  const chartOfAccountGstCodes = ['100', '200', '300']
  return chartOfAccountGstCodes.includes(chartOfAccount?.code?.split('-')[0])
})

const journalTypeGstOptionsData: any = useComputedHook((index: number) => {
  return !chartOfAccountGstOptional(journalTypeForm.value.entryLines[index].chartOfAccountId).value &&
    journalTypeForm.value.entryLines[index].chartOfAccountId !== '0'
    ? [...journalTypeGstOptions, { label: 'N/A', value: 'NA' }]
    : journalTypeGstOptions
})

const rules = reactive<FormRules>({
  name: [
    {
      required: true,
      trigger: 'blur',
      message: 'Account name is required'
    }
  ],
  journalNoPrefix: [
    {
      required: true,
      trigger: 'blur',
      validator(rule: any, value: string) {
        if (!value) {
          return new Error('Journal No Prefix is required')
        }
        if (!/^[a-zA-Z]*$/.test(value)) {
          return new Error('Only English letters are allowed')
        }
        return true
      }
    }
  ],
  code: [
    {
      required: true,
      trigger: 'blur',
      validator(rule: any, value: string) {
        if (!value) {
          return new Error('ID is required')
        }
        if (!/^\d*$/.test(value)) {
          return new Error('Only numbers are allowed')
        }
        return true
      }
    }
  ],
  isBrokerInventory: [
    {
      required: true,
      trigger: 'blur',
      validator() {
        if (brokerInventoryValidate.value) {
          if (!journalTypeForm.value.isBrokerInventory) {
            return true
          }
          const meetTheCriteria = journalTypeForm.value.entryLines.some((i: any) => {
            const chartOfAccount = currentChartOfAccount(i?.chartOfAccountId).value
            if (
              ['ASSET', 'LIABILITY'].includes(chartOfAccount?.type) &&
              chartOfAccount?.currencyTypes?.[0] !== 'REPORTING'
            ) {
              return true
            }
            return false
          })
          return !meetTheCriteria ? new Error('') : true
        }
        return true
      }
    }
  ]
})

const onAddEntry = (balanceType: string) => {
  journalTypeForm.value.entryLines.push({
    index: journalTypeForm.value.entryLines.length,
    chartOfAccountId: '',
    chartOfAccountCalculator: '',
    balanceType,
    gstType: '',
    gstRatio: 0
  })
}

const onDeleteEntry = (index: number) => {
  if (hasGstType.value) return
  journalTypeForm.value.entryLines.splice(index, 1)
  journalTypeForm.value.entryLines.forEach((item: any, i: number) => {
    item.index = i
  })
}

const onChangeGSTRateType = async (index: number | null = null) => {
  if (index !== null) {
    if (
      journalTypeForm.value.entryLines[index].gstType === 'NONE' ||
      journalTypeForm.value.entryLines[index].gstType === 'ZERO' ||
      journalTypeForm.value.entryLines[index].gstType === 'EXEMPT'
    ) {
      journalTypeForm.value.entryLines[index].gstRatio = 0
    } else {
      journalTypeForm.value.entryLines[index].gstRatio = 9
    }
    switch (journalTypeForm.value.entryLines[index].gstType) {
      case 'NONE':
      case 'EXEMPT':
        if (journalTypeForm.value.entryLines?.length > 2) {
          const gstIndex = findIndex(journalTypeForm.value.entryLines, (i: any) => {
            const chartOfAccount = currentChartOfAccount(i.chartOfAccountId).value
            if (chartOfAccount?.slug === 'gst_input' || chartOfAccount?.slug === 'gst_output') return true
            return false
          })
          journalTypeForm.value.entryLines.splice(gstIndex, 1)
        }
        break
      case 'STANDARD':
      case 'ZERO':
        if (journalTypeForm.value.entryLines?.length === 2) {
          journalTypeForm.value.entryLines[index === 0 ? 1 : 0].gstType =
            journalTypeForm.value.entryLines[index === 0 ? 1 : 0].gstType === 'NA' ? 'NA' : 'NONE'
          journalTypeForm.value.entryLines.splice(index === 0 ? 2 : 1, 0, {
            index: 2,
            chartOfAccountId: gstOutputChartOfAccountId.value,
            chartOfAccountCalculator: '',
            balanceType: index === 0 ? 'Cr' : 'Dr',
            gstType: 'NA',
            gstRatio: 0
          })
        } else {
          const gstIndex = findIndex(journalTypeForm.value.entryLines, (i: any) => {
            const chartOfAccount = currentChartOfAccount(i.chartOfAccountId).value
            if (chartOfAccount?.slug === 'gst_input' || chartOfAccount?.slug === 'gst_output') return true
            return false
          })
          journalTypeForm.value.entryLines.splice(gstIndex, 1)
          journalTypeForm.value.entryLines.splice(index === 0 ? 2 : 1, 0, {
            index: 2,
            chartOfAccountId: gstOutputChartOfAccountId.value,
            chartOfAccountCalculator: '',
            balanceType: index === 0 ? 'Cr' : 'Dr',
            gstType: 'NA',
            gstRatio: 0
          })
        }
        break
      default:
        if (journalTypeForm.value.entryLines?.length === 2) {
          journalTypeForm.value.entryLines[index === 0 ? 1 : 0].gstType =
            journalTypeForm.value.entryLines[index === 0 ? 1 : 0].gstType === 'NA' ? 'NA' : 'NONE'
          journalTypeForm.value.entryLines.splice(index === 0 ? 2 : 1, 0, {
            index: 2,
            chartOfAccountId: gstInputChartOfAccountId.value,
            chartOfAccountCalculator: '',
            balanceType: index === 0 ? 'Cr' : 'Dr',
            gstType: 'NA',
            gstRatio: 0
          })
        } else {
          const gstIndex = findIndex(journalTypeForm.value.entryLines, (i: any) => {
            const chartOfAccount = currentChartOfAccount(i.chartOfAccountId).value
            if (chartOfAccount?.slug === 'gst_input' || chartOfAccount?.slug === 'gst_output') return true
            return false
          })
          journalTypeForm.value.entryLines.splice(gstIndex, 1)
          journalTypeForm.value.entryLines.splice(index === 0 ? 2 : 1, 0, {
            index: 2,
            chartOfAccountId: gstInputChartOfAccountId.value,
            chartOfAccountCalculator: '',
            balanceType: index === 0 ? 'Cr' : 'Dr',
            gstType: 'NA',
            gstRatio: 0
          })
        }
        break
    }
    journalTypeForm.value.entryLines.forEach((item: any, i: number) => {
      item.index = i
      if (item.gstType !== 'NONE' && item.gstType !== '' && item.gstType !== 'NA') {
        originalGstTypeIndex.value = i
      }
    })
  }
  if (hasGstType.value) {
    journalTypeForm.value.isBrokerInventory = false
  }
}

const onCheckJournalTypeDialog = () => {
  showJournalTypeDialog.value = !showJournalTypeDialog.value
}

const onChangeBrokerInventoryType = () => {
  brokerInventoryValidate.value = false
  formRef.value?.validateField('isBrokerInventory')
}

// 重置Entry恢复初始状态
const onResetEntry = (index: number | null = null) => {
  // 重置单个
  if (index !== null) {
    journalTypeForm.value.entryLines[index] = {
      index,
      chartOfAccountId: '',
      chartOfAccountCalculator: '',
      balanceType: journalTypeForm.value.entryLines[index].balanceType,
      gstRatio: journalTypeForm.value.entryLines[index].gstRatio ?? 9,
      gstType: journalTypeForm.value.entryLines[index].gstType ?? ''
    }
  } else {
    // 重置全部
    journalTypeForm.value.entryLines = [
      {
        index: 0,
        chartOfAccountId: '',
        chartOfAccountCalculator: '',
        balanceType: 'Dr',
        gstType: '',
        gstRatio: 0
      },
      {
        index: 1,
        chartOfAccountId: '',
        chartOfAccountCalculator: '',
        balanceType: 'Cr',
        gstType: '',
        gstRatio: 0
      }
    ]
  }
}

// 选择ChartOfAccount
const onChangeChartOfAccount = async (index: number) => {
  if (enableGst.value) {
    if (journalTypeForm.value.entryLines?.[index]?.chartOfAccountId) {
      const isChartOfAccountGstOptional = chartOfAccountGstOptional(
        journalTypeForm.value.entryLines[index].chartOfAccountId
      ).value
      journalTypeForm.value.entryLines[index].gstType = isChartOfAccountGstOptional ? 'NONE' : 'NA'
    } else {
      onResetEntry(index)
    }
    if (journalTypeForm.value.entryLines?.length > 2 && originalGstTypeIndex.value === index) {
      const removeEntryLine = (i: number, balanceType: string) => {
        if (journalTypeForm.value.entryLines[i].balanceType === balanceType) {
          journalTypeForm.value.entryLines.splice(i, 1)
        }
      }
      journalTypeForm.value.entryLines.forEach((item: any) => {
        const chartOfAccountVal = currentChartOfAccount(item.chartOfAccountId).value
        if (chartOfAccountVal?.slug === 'gst_output' || chartOfAccountVal?.slug === 'gst_input') {
          removeEntryLine(item.index, item.balanceType)
        }
      })
      journalTypeForm.value.entryLines.forEach((item: any, i: number) => {
        item.index = i
      })
    }
  }
}

const onSelectTreasuryAccount = (index: number) => {
  journalTypeForm.value.entryLines[index].chartOfAccountId = '0'
  journalTypeForm.value.entryLines[index].chartOfAccountCalculator = 'TREASURY_ACCOUNT'
  if (enableGst.value) {
    journalTypeForm.value.entryLines[index].gstType = 'NONE'
    journalTypeForm.value.entryLines[index].gstRatio = 0
  }
}

const onCreateJournalType = async () => {
  try {
    const params: any = cloneDeep(journalTypeForm.value)
    if (enableGst.value && params.entryLines.length > 2) {
      params.entryLines = params.entryLines.filter((item: any) => {
        if (
          item.gstType === 'NA' &&
          (item.chartOfAccountId === gstOutputChartOfAccountId.value ||
            item.chartOfAccountId === gstInputChartOfAccountId.value)
        )
          return false
        return true
      })
    }
    params.entryLines.forEach((item: any) => {
      item.gstRatio /= 100
      item.chartOfAccountCalculator = item.chartOfAccountCalculator ?? ''
      delete item.index
      if (!enableGst.value) {
        delete item.gstType
        delete item.gstRatio
      }
    })

    await LedgerApi.createJournalType(entityId.value, params)
    ElMessage.success(t('message.success'))
    emit('onResetList')
    onCheckJournalTypeDialog()
  } catch (error: any) {
    console.log(error)
    ElMessage.error(error.message)
  } finally {
    saveLoading.value = false
  }
}

const onEditJournalType = async () => {
  try {
    const params: any = cloneDeep(journalTypeForm.value)
    if (enableGst.value && params.entryLines.length > 2) {
      params.entryLines = params.entryLines.filter((item: any) => {
        if (
          (item.gstType === 'NA' || item.gstType === '') &&
          (item.chartOfAccountId === gstOutputChartOfAccountId.value ||
            item.chartOfAccountId === gstInputChartOfAccountId.value)
        )
          return false
        return true
      })
    }
    params.entryLines.forEach((item: any) => {
      item.gstRatio /= 100
      item.chartOfAccountCalculator = item.chartOfAccountId === '0' ? item.chartOfAccountCalculator : ''
      delete item.index
      if (!enableGst.value) {
        delete item.gstType
        delete item.gstRatio
      }
    })
    await LedgerApi.editJournalType(entityId.value, props.currentData?.journalTypeId, params)
    ElMessage.success(t('message.success'))
    emit('onResetList')
    onCheckJournalTypeDialog()
  } catch (error: any) {
    console.log(error)
    ElMessage.error(error.message)
  } finally {
    saveLoading.value = false
  }
}

const onSaveChartOfAccount = () => {
  if (!formRef.value) return
  if (projectDetail.value?.plan !== ProjectPermissionLevel.OLD_BASIC) {
    brokerInventoryValidate.value = true
  }
  formRef.value?.validate(async (isValidate: boolean) => {
    if (isValidate) {
      saveLoading.value = true
      if (props.model === 'add') {
        onCreateJournalType()
      } else {
        onEditJournalType()
      }
    }
  })
}

const onCloseDialog = () => {
  formRef.value?.resetFields()
  brokerInventoryValidate.value = false
  journalTypeForm.value = {
    name: '',
    journalNoPrefix: '',
    code: '',
    group: 'OPERATING',
    entryLines: [
      {
        index: 0,
        chartOfAccountId: '',
        chartOfAccountCalculator: '',
        balanceType: 'Dr',
        gstType: '',
        gstRatio: 0
      },
      {
        index: 1,
        chartOfAccountId: '',
        chartOfAccountCalculator: '',
        balanceType: 'Cr',
        gstType: '',
        gstRatio: 0
      }
    ],
    isBrokerInventory: false
  }
}

defineExpose({ onCheckJournalTypeDialog })

watch(
  [() => showJournalTypeDialog.value, () => props.currentData],
  async () => {
    if (
      showJournalTypeDialog.value &&
      props.model === 'edit' &&
      !isEmpty(props.currentData) &&
      props.currentData !== null
    ) {
      dialogLoading.value = true
      try {
        const { data } = await LedgerApi.getJournalTypeDetail(entityId.value, props.currentData?.journalTypeId)
        journalTypeDetail.value = data
        dialogLoading.value = false
        journalTypeForm.value = {
          name: data.name,
          journalNoPrefix: data.journalNoPrefix,
          code: data.code,
          group: data.group,
          entryLines: data.entryLines.map((item: any, index: number) => {
            return {
              index,
              chartOfAccountId: item.chartOfAccountId,
              chartOfAccountCalculator: item.chartOfAccountCalculator,
              balanceType: item.balanceType,
              gstType: item.gstType,
              gstRatio: item.gstRatio !== 0 ? item.gstRatio * 100 : 0
            }
          }),
          isBrokerInventory: data.isBrokerInventory ?? false
        }
        checkStatusLoading.value = true
        const response = await LedgerApi.checkJournalTypeUsage(entityId.value, props.currentData?.journalTypeId)
        checkStatusData.value = response.data
      } catch (error) {
        console.log(error)
      } finally {
        checkStatusLoading.value = false
        dialogLoading.value = false
      }
    }
  },
  {
    immediate: true
  }
)
</script>

<style lang="scss">
.elv-ledger-journal-type-dialog {
  width: 700px;
  box-shadow:
    0px 2px 6px rgba(0, 0, 0, 0.05),
    0px 0px 1px rgba(0, 0, 0, 0.3);
  border-radius: 6px;

  .el-dialog__header {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    height: 54px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    margin: 0;
    padding: 0;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    color: #0e0f11;
    padding-left: 24px;
    border-bottom: 1px solid #edf0f3;

    .elv-ledger-journal-type-dialog-header__title {
      font-family: 'Plus Jakarta Sans';
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #0e0f11;
      margin: 0;
    }
  }

  .el-dialog__body {
    padding: 18px 25px 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .elv-ledger-journal-type-content {
      border-radius: 4px;
      min-height: 170px;
      width: 100%;
    }

    .el-select .el-select__wrapper.is-disabled {
      .el-select__selected-item {
        color: #0e0f11;
      }
    }

    .elv-ledger-journal-type-form-item__base {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      gap: 8px;
      align-self: stretch;
      height: 92px;
      margin-bottom: 16px;

      .el-form-item {
        margin-bottom: 24px !important;

        .el-input,
        .el-select {
          &.is-short {
            .el-input__wrapper,
            .el-select__wrapper {
              width: 211.33px;
            }
          }

          &.is-shorter {
            .el-input__wrapper,
            .el-select__wrapper {
              width: 156px;
            }
          }
        }

        .elv-ledger-journal-type-form-broker-error {
          color: #df2200;
          font-family: 'Plus Jakarta Sans';
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-top: 8px;
          cursor: pointer;
        }
      }
    }

    .elv-ledger-journal-type-form-item__line {
      color: #838d95;
      font-family: 'Plus Jakarta Sans';
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 16px;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        width: 583px;
        height: 1px;
        background: #edf0f3;
        bottom: 50%;
        right: 0;
        transform: translateY(50%);
      }

      &.entryLines::after {
        width: 583px;
      }
    }

    .elv-ledger-journal-type-form-item__entry-list {
      width: 100%;
      box-sizing: border-box;
      border-radius: 5px;
      background: #f9fafb;
      display: flex;
      padding: 8px;
      flex-direction: column;
      align-items: flex-start;
      align-self: stretch;
      min-height: 40px;
      gap: 8px;
    }

    .elv-ledger-journal-type-form-item__entry-add {
      margin-top: 16px;

      svg {
        margin-right: 4px;
        fill: #838d95;
      }

      &:not(.is-disabled) {
        color: #1753eb !important;

        svg {
          fill: #1753eb;
          margin-right: 4px;
        }
      }
    }

    .elv-ledger-journal-type-form-item__entry-title {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      gap: 8px;

      span {
        color: #636b75;
        font-family: 'Plus Jakarta Sans';
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        display: block;
        width: 194px;

        &:first-of-type {
          width: 100px;
        }
      }
    }

    .elv-ledger-journal-type-form-item__entry-item {
      display: flex;
      align-items: center;
      gap: 8px;
      width: 100%;
      position: relative;

      .el-form-item {
        margin-bottom: 0px !important;
      }

      .elv-ledger-journal-type-form-item__entry-item__balance {
        display: flex;
        height: 44px;
        width: 100px;
        box-sizing: border-box;
        flex-direction: column;
        background: #f9fafb;
        justify-content: center;
        align-items: flex-start;
        border-radius: 4px;
        border: 1px solid #dde1e6;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
        padding: 8px 12px;
        color: #0e0f11;
        font-family: 'Plus Jakarta Sans';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        cursor: not-allowed;
      }

      .elv-ledger-journal-type-form-item__entry-item__type {
        .el-select__wrapper {
          width: 194px;
        }
      }

      .elv-ledger-journal-type-form-item__entry-item__percent {
        position: relative;

        .el-input__wrapper {
          width: 80px;
          padding: 0 12px;

          .el-input__inner {
            width: 30px;
            text-align: left;
          }
        }

        .el-input-number {
          width: 80px;
        }

        .symbol {
          padding-right: 12px;
          padding-left: 0px;
          color: #636b75;
          text-align: right;
          font-family: 'Barlow';
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 28px;
          height: 28px;
          position: absolute;
          right: 1px;
          bottom: 10px;
        }
      }

      .elv-ledger-journal-type-form-item__entry-item__delete {
        width: 36px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 0;
        top: 0;

        svg {
          &:not(.is-disabled):hover {
            cursor: pointer;
            fill: #1753eb;
          }

          &.is-disabled {
            fill: #d0d4d9;
            cursor: not-allowed;
          }
        }
      }
    }

    .elv-ledger-journal-type-checking {
      width: 100%;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      color: #1753eb;
      font-family: 'Plus Jakarta Sans';
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;

      img {
        display: block;
        width: 16px;
        height: 16px;
        margin-right: 4px;
        animation: loading-rotate 2s linear infinite;
      }
    }

    .elv-ledger-journal-type-info {
      width: 100%;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      color: #636b75;
      font-family: 'Plus Jakarta Sans';
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;

      svg {
        margin-right: 4px;
      }
    }
  }

  .el-form.el-form--default {
    .el-form-item {
      margin-bottom: 16px;

      &:last-of-type {
        margin-bottom: 0px;
      }

      &.is-error {
        .el-input__wrapper {
          background: #faeee6;
          border: 1px solid #7e4a15;
        }

        .el-input__inner {
          color: #7e4a15;
        }

        .el-form-item__error {
          font-family: 'Plus Jakarta Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #7e4a15;
        }
      }
    }

    .el-form-item__label {
      font-family: 'Plus Jakarta Sans';
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      color: #636b75;
      margin-bottom: 8px;

      &::before {
        // 隐藏掉必填的小红星
        display: none;
      }
    }

    .el-input__wrapper {
      width: 432px;
      box-sizing: border-box;
    }

    .el-input {
      height: 44px;
      border-radius: 4px;

      &.is-disabled {
        background: #f9fafb;
      }
    }

    .el-input__inner {
      font-family: 'Plus Jakarta Sans';
      font-weight: 400;
      font-size: 14px;
      color: #0e0f11;
    }

    .el-input:not(.is-disabled) .el-input__wrapper {
      &:hover {
        border: 1px solid #7596eb !important;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
      }

      &.is_focus {
        border: 1px solid #7596eb !important;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
      }
    }

    .el-select {
      &:hover:not(.el-select--disabled) .el-input__wrapper,
      .el-input.is-focus:not(.el-select--disabled) .el-input__wrapper {
        border: 1px solid #7596eb !important;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
      }

      .el-select__selected-item {
        .el-tag {
          padding: 0 16px;
          height: 24px;
          border-radius: 40px;
          border: 1px solid #d0d4d9;
          box-sizing: border-box;
          color: #636b75;
          background-color: #fff;
          leading-trim: both;
          text-edge: cap;
          font-family: 'Plus Jakarta Sans';
          font-size: 11px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }

      .el-select__selection {
        .el-tag {
          display: flex;
          padding: 0px 8px;
          box-sizing: border-box;
          justify-content: center;
          align-items: center;
          gap: 2px;
          border-radius: 3px;
          border: 1px solid #dde1e6;
          background: #edf0f3;

          .el-tag__content {
            color: #1753eb;
            font-family: 'Barlow';
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
          }

          .el-icon.el-tag__close {
            color: #aaafb6;

            &:hover {
              color: #fff;
              background-color: #1753eb;
            }
          }
        }
      }
    }

    .el-input__wrapper.is-focus {
      border: 1px solid #7596eb !important;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
    }
  }

  .el-dialog__footer {
    padding: 20px 0px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.elv-ledger-journal-type__dropdown-popper {
  overflow: hidden;
  transform: translateY(-11.5px);

  .elv-ledger-journal-type__dropdown {
    padding: 0;

    .el-dropdown-menu__item {
      box-sizing: border-box;
      width: 140px;
      height: 32px;
      background: #ffffff;
      padding: 0px 8px;
      font-size: 12px;
      color: #1e2024;
      font-family: 'Plus Jakarta Sans';
      justify-content: flex-start;
      align-items: center;

      &.is-disabled {
        background-color: #f9fafb;
      }
    }

    .el-dropdown-menu__item:not(.is-disabled):focus {
      background-color: $elv-color-F9FBFE;
      color: $elv-color-0E1420;
    }
  }
}

.elv-ledger-journal-type-form-broker-tips.el-popper {
  width: 220px;
  overflow: unset;

  .el-popper__arrow {
    display: block;
  }
}

.elv-journal-drawer-filter-tips.el-popper {
  display: flex;
  gap: 6px;
  max-width: 400px;
  max-height: 200px;
  flex-wrap: wrap;
}
</style>
